import React from 'react'

// Spinner
export const Loader = () => {
    return (
        <main className="fixed top-0 left-0 w-full h-dvh bg-[#0000004d] z-10 grid place-items-center !m-0">
            <span className="loader size-10 border-4" />
        </main>
    )
};


// Content Loader
export const ContentLoader = ({ columns }) => {
    return (
        <>
            {[...Array(columns)].map((_, i) => (
                <td key={i} className="py-2 pl-3 pr-12 !bg-white h-11">
                    <div className="content-loader h-3 rounded-full" />
                </td>
            ))}
        </>
    )
};