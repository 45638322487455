import React from 'react'
import { tooltip } from '../assets'

export const Tooltip = ({ message, top }) => {
    return (
        <div className="relative group cursor-pointer w-fit">
            <img src={tooltip} alt="Tooltip" />
            <div className={`absolute hidden group-hover:block bg-[--sec-gray] text-white text-xs rounded py-2 px-4 z-10 w-max ${top ? 'bottom-full left-1/2 -translate-x-1/2 -translate-y-2' : 'top-1/2 left-full -translate-y-1/2 translate-x-2'}`}>
                {message}
                <div className={`absolute size-0 ${top ?
                    '-bottom-2 left-1/2 -translate-x-1/2 border-t-8 border-t-[--sec-gray] border-x-8 border-x-transparent' :
                    'top-1/2 -left-2 -translate-y-1/2 border-r-8 border-r-[--sec-gray] border-y-8 border-y-transparent'
                    }`} />
            </div>
        </div>
    )
}