import React, { useState, useEffect } from "react";

import Draggable from "react-draggable";
import axios from "axios";

import { closeBlack, closeRed, closeWhite } from "../assets";
import { Loader, Navbar, Tooltip } from "../Components";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";


export const PurchaseDataForm = ({ onClose }) => {
  const [products, setProducts] = useState([{ name: "", productCode: "", itemQuantity: null, costPrice: null, totalCost: null }]);
  const [purchaseData, setPurchaseData] = useState({
    supplierName: "",
    supplierCode: "",
    supplierContactNumber: "",
    supplierLocation: "",
    extraCharges: null,
    totalAmount: null,
    purchaseOrderNumber: "",
    paymentMethod: "Cash",
    purchaseDate: "",
    deliveryDate: "",
    comments: "",
  });
  const [loader, setLoader] = useState(false);

  const [isSmallScreen, setIsSmallScreen] = useState(() => window.innerWidth < 1023);
  const [position, setPosition] = useState(() => {
    if (window.innerWidth > 1023) {
      const x = (window.innerWidth - 940) / 2;
      const y = (window.innerHeight - 767) / 2;
      return { x, y };
    } else {
      return { x: 0, y: 0 };
    }
  });


  useEffect(() => {
    const updatedProducts = products.map((product) => ({ ...product, totalCost: product.itemQuantity * product.costPrice || 0 }));
    setProducts(updatedProducts);

    const totalProductCost = updatedProducts.reduce((acc, product) => acc + product.totalCost, 0);

    const totalAmount = totalProductCost + Number(purchaseData.extraCharges) || 0;
    setPurchaseData((prevData) => ({ ...prevData, totalAmount, }));
  }, [products]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    axios.post(`${apiUrl}/api/purchase/add`, {
      ...purchaseData, products,
    }, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then((res) => {
      console.log(res.data);
      onClose();
    }).catch((err) => console.error(err)).finally(() => setLoader(false));
  };

  const handleChange = (index, field, value) => {
    const newItems = [...products];
    newItems[index][field] = value;
    setProducts(newItems);
  };

  const addProduct = () => setProducts([...products, { name: "", productCode: "", itemQuantity: null, costPrice: null, totalCost: null }]);
  const removeProduct = (index) => products.length > 1 && setProducts(products.filter((_, i) => i !== index));


  return (
    <>
      <div className="fixed inset-0 bg-black bg-opacity-60 z-10 !mt-0" />
      <Draggable defaultPosition={position} disabled={isSmallScreen}>
        <form
          className="fixed top-0 left-0 lg:w-[940px] lg:rounded-[--radius-main] bg-white z-10 !mt-0 max-lg:w-full"
          onSubmit={handleSubmit}
        >
          <div className="lg:hidden"><Navbar /></div>

          <div className="flex justify-between lg:bg-[--btn-primary-blue] lg:text-white px-6 lg:px-10 pt-4 lg:py-2 lg:rounded-t-[--radius-main]">
            <h3>Purchase Data Form</h3>
            <div onClick={onClose} className="cursor-pointer hover:bg-[#ffffff0d] content-center">
              <img src={closeWhite} alt="Close icon" title="Close" className="max-lg:hidden" />
              <img src={closeBlack} alt="Close icon" title="Close" className="lg:hidden" />
            </div>
          </div>

          <div className="h-sm-form lg:max-h-[600px] overflow-y-auto max-lg:mt-4 px-6 pb-4 lg:p-10 space-y-4 lg:space-y-6">
            <div className="bg-[--primary-gray] grid grid-cols-1 gap-y-6 p-4 lg:px-5 lg:py-[18px] rounded-md">
              <div className="flex justify-end max-lg:order-1">
                <button onClick={addProduct} className="btn btn-outline bg-white max-lg:w-full">+ Add Item</button>
              </div>

              {products.map((item, i) => (
                <div key={i} className={`grid grid-cols-3 lg:flex gap-y-3 gap-x-2 lg:gap-x-5 items-end text-base font-semibold max-lg:pb-4 ${i > 0 && "max-lg:border-t max-lg:border-[--sec-gray] max-lg:pt-2.5"}`}>
                  <div className="flex flex-col gap-y-1 lg:gap-y-2 lg:w-[266px] col-span-2">
                    <label htmlFor="productName">Product Name*</label>
                    <input
                      type="text"
                      placeholder="Galaxy"
                      name="productName"
                      id="productName"
                      required
                      value={item.name}
                      onChange={(e) => handleChange(i, "name", e.target.value)}
                    />
                  </div>

                  <div className="flex flex-col gap-y-2 lg:w-[150px]">
                    <label htmlFor="productCode">Product Code*</label>
                    <input
                      type="text"
                      placeholder="INK-001"
                      name="productCode"
                      id="productCode"
                      required
                      value={item.code}
                      onChange={(e) => handleChange(i, "productCode", e.target.value)}
                    />
                  </div>

                  <div className="flex flex-col gap-y-2 lg:w-[100px]">
                    <label htmlFor="itemQty">Item Qty*</label>
                    <input
                      type="number"
                      placeholder="50"
                      name="itemQty"
                      id="itemQty"
                      required
                      value={item.quantity}
                      onChange={(e) => handleChange(i, "itemQuantity", Number(e.target.value))}
                    />
                  </div>

                  <div className="flex flex-col gap-y-2 lg:w-[100px]">
                    <label htmlFor="costPrice">Cost Price*</label>
                    <input
                      type="number"
                      placeholder="1200"
                      name="costPrice"
                      id="costPrice"
                      required
                      value={item.price}
                      onChange={(e) => handleChange(i, "costPrice", Number(e.target.value))}
                    />
                  </div>

                  <div className="flex flex-col gap-y-2 lg:w-[100px]">
                    <label htmlFor="totalCost">Total Cost*</label>
                    <input
                      type="number"
                      placeholder="60000"
                      name="totalCost"
                      id="totalCost"
                      required
                      value={item.totalCost}
                      readOnly
                    />
                  </div>

                  <img src={closeRed} alt="close" onClick={() => removeProduct(i)} className="cursor-pointer pb-2 max-lg:-order-1 max-lg:col-span-3 max-lg:justify-self-end" />
                </div>
              ))}
            </div>

            <div className="grid grid-cols-2 lg:grid-cols-4 gap-y-3 lg:gap-y-6 gap-x-2 lg:gap-x-5 text-base font-semibold text-[--text-primary] overflow-y-auto">
              <div className="flex flex-col lg:col-span-2 gap-y-1 lg:gap-y-2">
                <label htmlFor="supplierName">Supplier Name*</label>
                <input
                  type="text"
                  placeholder="Wasim"
                  required
                  name="supplierName"
                  id="supplierName"
                  value={purchaseData.supplierName}
                  onChange={(e) => setPurchaseData({ ...purchaseData, supplierName: e.target.value, })}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2 lg:col-span-2">
                <label htmlFor="supplierContactNumber">Supplier Contact No.</label>
                <input
                  type="text"
                  placeholder="03211122334"
                  name="supplierContactNumber"
                  id="supplierContactNumber"
                  value={purchaseData.supplierContactNumber}
                  onChange={(e) => setPurchaseData({ ...purchaseData, supplierContactNumber: e.target.value, })}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="supplierLocation">Supplier Location</label>
                <input
                  type="text"
                  placeholder="Karachi"
                  name="supplierLocation"
                  id="supplierLocation"
                  value={purchaseData.supplierLocation}
                  onChange={(e) => setPurchaseData({ ...purchaseData, supplierLocation: e.target.value, })}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="supplierCode">Supplier Code</label>
                <input
                  type="text"
                  placeholder="SPL100A"
                  name="supplierCode"
                  id="supplierCode"
                  value={purchaseData.supplierCode}
                  onChange={(e) => setPurchaseData({ ...purchaseData, supplierCode: e.target.value, })}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <div className="flex gap-x-1 lg:gap-x-2 items-center">
                  <label htmlFor="extraCharges">Extra Charges</label>
                  <Tooltip message="Delivery, Bilty, etc." top={true} />
                </div>
                <input
                  type="number"
                  placeholder="1000"
                  name="extraCharges"
                  id="extraCharges"
                  value={purchaseData.extraCharges}
                  onChange={(e) => setPurchaseData({ ...purchaseData, extraCharges: e.target.value, })}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="totalAmount">Total Amount*</label>
                <input
                  type="number"
                  placeholder="60000"
                  name="totalAmount"
                  id="totalAmount"
                  value={purchaseData.totalAmount}
                  readOnly
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="purchaseOrderNumber">Purchase Order Number</label>
                <input
                  type="text"
                  placeholder="AX001"
                  name="purchaseOrderNumber"
                  id="purchaseOrderNumber"
                  value={purchaseData.purchaseOrderNumber}
                  onChange={(e) => setPurchaseData({ ...purchaseData, purchaseOrderNumber: e.target.value, })}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="paymentMethod">Payment Method*</label>
                <select
                  name="paymentMethod"
                  id="paymentMethod"
                  required
                  value={purchaseData.paymentMethod}
                  onChange={(e) => setPurchaseData({ ...purchaseData, paymentMethod: e.target.value, })}
                >
                  <option>Cash</option>
                  <option>Online</option>
                </select>
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="purchaseDate">Purchased Date*</label>
                <input
                  type="date"
                  name="purchaseDate"
                  id="purchaseDate"
                  required
                  value={purchaseData.purchaseDate}
                  onChange={(e) => setPurchaseData({ ...purchaseData, purchaseDate: e.target.value, })}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2">
                <label htmlFor="deliveryDate">Delivery Date</label>
                <input
                  type="date"
                  name="deliveryDate"
                  id="deliveryDate"
                  value={purchaseData.deliveryDate}
                  onChange={(e) => setPurchaseData({ ...purchaseData, deliveryDate: e.target.value, })}
                />
              </div>

              <div className="flex flex-col gap-y-1 lg:gap-y-2 col-span-2 lg:col-span-4">
                <label htmlFor="comments">Comments</label>
                <textarea
                  type="text"
                  placeholder="We sold this item in less amount than purchase"
                  name="comments"
                  id="comments"
                  value={purchaseData.comments}
                  onChange={(e) => setPurchaseData({ ...purchaseData, comments: e.target.value, })}
                />
              </div>
            </div>
          </div>

          <div className="px-6 py-8 lg:p-10 flex gap-x-3 lg:gap-x-5 border-t border-[#AFB3B8]">
            <button type="submit" className="btn btn-blue">Add Entry</button>
            <button type="reset" className="btn btn-outline">Reset</button>
          </div>
        </form>
      </Draggable>

      {loader && <Loader />
      }
    </>
  );
};
