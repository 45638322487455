import React, { useEffect, useState } from "react";
import axios from "axios";

import { Navbar, Footer, Popup, Loader, ContentLoader } from "../Components";
import { doubleArrow, search, deleteIcon } from "../assets";

import throwError from "../utils/catch";
import sortData from "../utils/sortData";
import formatDate from "../utils/formatDate";

const apiUrl = process.env.REACT_APP_API_BASE_URL || "http://localhost:5000";

export const Purchase = () => {
  const [purchases, setPurchases] = useState([]);
  const [popupData, setPopupData] = useState({ isSuccess: false, message: "" });
  const [loader, setLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [contentLoader, setContentLoader] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [editingCell, setEditingCell] = useState({ purchaseId: null, field: null });
  const [editedValue, setEditedValue] = useState('');
  const [editedProducts, setEditedProducts] = useState([]);


  useEffect(() => getPurchaseProducts(), []);

  const getPurchaseProducts = () => {
    setContentLoader(true);

    axios.get(`${apiUrl}/api/purchase/get`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
    }).then((res) => {
      console.log(res.data.message);
      setPurchases(res.data.purchases);
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
    }).finally(() => setContentLoader(false));
  };

  const deletePurchase = (id) => {
    setLoader(true);

    axios.delete(`${apiUrl}/api/purchase/delete/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("token")}` }
    }).then((res) => {
      console.log(res.data);
      getPurchaseProducts();
    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    }).finally(() => setLoader(false));
  };

  const handleUpdate = (purchaseId, field, value) => {
    if (purchaseId) {
      // Check if the field is updated or not
      let updatedPurchase = purchases.find(purchase => purchase._id === purchaseId);

      // Checking purchase date
      if (field === "purchaseDate") {
        value = new Date(value);

        if (formatDate(updatedPurchase[field]) === formatDate(value)) {
          setEditingCell({ purchaseId: null, field: null });
          setEditedValue('');
          return;
        }
      }

      // Checking strings and numbers
      if (updatedPurchase[field] === value) {
        setEditingCell({ purchaseId: null, field: null });
        setEditedValue('');
        return;
      }

      setLoader(true);

      if (field === 'totalAmount' && isNaN(value)) {
        alert('Please enter a valid number');
        return;
      }

      if (field === 'purchaseDate' && !value) {
        alert('Please select a valid date');
        return;
      }

      axios.put(`${apiUrl}/api/purchase/update/${purchaseId}`, {
        [field]: value
      }, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      }).then((res) => {
        console.log(res.data.message);

        // Update the local purchases state
        getPurchaseProducts();

        // Reset editing state
        setEditingCell({ purchaseId: null, field: null });
        setEditedValue('');

        // Showing popup after updating
        setPopupData({
          isSuccess: true,
          message: res.data.message || "Purchase updated successfully"
        });
        setShowPopup(true);
      }).catch((err) => {
        console.error(err);
        throwError(err, setPopupData);
        setEditingCell({ purchaseId: null, field: null });
        setEditedValue('');
      }).finally(() => setLoader(false));

    } else {
      // Resetting the field
      setEditingCell({ purchaseId: null, field: null });
      setEditedValue('');
    }
  };

  const handleProductChange = (index, field, value) => {
    setEditedProducts((prevProducts) => {
      const newProducts = [...prevProducts];

      // Only allow changes to 'productQuantity' and 'totalCost'
      if (field === 'itemQuantity' || field === 'costPrice' || field === 'totalCost') {
        newProducts[index][field] = value;
      }

      return newProducts;
    });
  };

  const handleUpdateProducts = (purchaseId) => {
    setLoader(true);

    // Validate the edited products before sending
    const valid = editedProducts.every((product) => {
      return (
        product.itemQuantity > 0 &&
        product.costPrice >= 0 &&
        product.totalCost >= 0
      );
    });

    if (!valid) {
      setPopupData({
        isSuccess: false,
        message: "Please enter valid product quantities, cost price and total cost."
      });
      setShowPopup(true);
      console.error("Please enter valid product quantities, cost price and total cost.");
      return;
    }

    // Prepare data to send to backend
    const productsToSend = editedProducts.map((product) => ({
      ...product,
      itemQuantity: parseFloat(product.itemQuantity),
      costPrice: parseFloat(product.costPrice),
      totalCost: parseFloat(product.totalCost)
    }));

    axios.put(`${apiUrl}/api/purchase/update/${purchaseId}`, { products: productsToSend }, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    }).then((res) => {
      console.log(res.data.message);

      // Update local state
      getPurchaseProducts();

      // Reset editing state
      setEditingCell({ purchaseId: null, field: null });
      setEditedProducts([]);

      // Showing popup after updating
      setPopupData({
        isSuccess: true,
        message: "Products in the purchase updated successfully"
      });
      setShowPopup(true);

    }).catch((err) => {
      console.error(err);
      throwError(err, setPopupData);
      setShowPopup(true);
    }).finally(() => setLoader(false));
  };

  const handleCancelEdit = () => {
    setEditingCell({ purchaseId: null, field: null });
    setEditedProducts([]);
  };

  const filteredPurchases = purchases.filter((purchase) =>
    purchase?.products?.some((product) => product?.name?.toLowerCase().includes(searchTerm.toLowerCase())));


  return (
    <>
      <Navbar />



      <main className="px-[25px] py-4 space-y-4 lg:px-[60px] lg:py-8 center lg:space-y-8 flex-grow">
        <section>
          <div className="bg-[--btn-primary-blue]  px-4 lg:px-10 py-2 rounded-t-[--radius-main]">
            <div className="relative flex justify-end">
              <input
                className="rounded-[22px] pl-reg lg:pr-10"
                type="text"
                placeholder="Search Item"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              <img
                src={search}
                alt="search"
                className="absolute right-4 top-1/2 -translate-y-1/2"
              />
            </div>
          </div>



          <div className="overflow-x-auto mb-4 lg:mb-16">
            <table className="w-full">
              <thead>
                <tr>
                  <th id="supplierName">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "string", "supplierName")}
                    >
                      <span>Supplier Name</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="item">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "array")}
                    >
                      <span>Item</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="totalAmount">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "number", "totalAmount")}
                    >
                      <span>Total Amount</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="paymentMethod">
                    <div
                      className="flex text-nowrap justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "string", "paymentMethod")}
                    >
                      <span>Payment Method</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="purchaseDate">
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer w-max"
                      onClick={() => sortData(purchases, setPurchases, "date", "purchaseDate")}
                    >
                      <span>Purchase Date</span>
                      <img src={doubleArrow} alt="Sort Icon" />
                    </div>
                  </th>

                  <th id="actions" />
                </tr>
              </thead>

              <tbody>
                {contentLoader ? (
                  <tr><ContentLoader columns={5} /></tr>
                ) : Array.isArray(filteredPurchases) && filteredPurchases.length !== 0 ? (
                  filteredPurchases.map((purchase) => (
                    <tr key={purchase?._id} className="*:px-4 *:py-2 h-11 ps-reg">

                      {/* Supplier Name */}
                      <td headers="supplierName" title="Double-Click to Edit"
                        onDoubleClick={() => {
                          setEditingCell({ purchaseId: purchase._id, field: 'supplierName' });
                          setEditedValue(purchase.supplierName);
                        }}>
                        {editingCell.purchaseId === purchase._id && editingCell.field === 'supplierName' ? (
                          <input className="w-full"
                            type="text"
                            value={editedValue}
                            onChange={(e) => setEditedValue(e.target.value)}
                            onBlur={() => handleUpdate(purchase._id, 'supplierName', editedValue)}
                            onKeyDown={(e) => e.key === "Enter" && handleUpdate(purchase._id, 'supplierName', editedValue)}
                            onKeyUp={(e) => e.key === "Escape" && handleUpdate()}
                            autoFocus
                          />
                        ) : (
                          purchase.supplierName
                        )}
                      </td>


                      {/* Products */}
                      <td headers="item" title="Double-Click to Edit Qty and Rs"
                        onDoubleClick={() => {
                          setEditingCell({ purchaseId: purchase._id, field: 'products' });
                          setEditedProducts(purchase.products);
                        }}>
                        {editingCell.purchaseId === purchase._id && editingCell.field === 'products' ? (
                          <div>
                            {editedProducts.map((product, index) => (
                              <div key={product._id || index} className="space-y-px">
                                {/* Product Name */}
                                <span className="font-semibold">{`${product.name} (${product.productCode})`}</span>

                                <div className="flex items-center gap-x-1">
                                  {/* Quantity */}
                                  <input
                                    type="number"
                                    value={product.itemQuantity}
                                    onChange={(e) => handleProductChange(index, 'itemQuantity', e.target.value)}
                                    min="1"
                                    placeholder="Quantity"
                                    className="border p-1"
                                    title="Quantity"
                                  />
                                  {/* Cost Price */}
                                  <input
                                    type="number"
                                    value={product.costPrice}
                                    onChange={(e) => handleProductChange(index, 'costPrice', e.target.value)}
                                    min="0"
                                    placeholder="Cost Price"
                                    className="border p-1"
                                    title="Cost Price"
                                  />
                                  {/* Total Cost */}
                                  <input
                                    type="number"
                                    value={product.totalCost}
                                    onChange={(e) => handleProductChange(index, 'totalCost', e.target.value)}
                                    min="0"
                                    placeholder="Total Cost"
                                    className="border p-1"
                                    title="Total Cost"
                                  />
                                </div>
                              </div>
                            ))}
                            {/* Save and Cancel Buttons */}
                            <div className="mt-2 space-x-2 *:p-1 *:text-white">
                              <button onClick={() => handleUpdateProducts(purchase._id)} className="bg-blue-500">
                                Save
                              </button>
                              <button onClick={handleCancelEdit} className="bg-gray-500">
                                Cancel
                              </button>
                            </div>
                          </div>
                        ) : (purchase?.products?.map((item) => (
                          <div key={item._id} className="w-max">
                            {item?.name} - {item?.itemQuantity} - Rs. {item?.totalCost}
                          </div>
                        )))}
                      </td>


                      {/* Total Amount */}
                      <td headers="totalAmount" title="Double-Click to Edit"
                        onDoubleClick={() => {
                          setEditingCell({ purchaseId: purchase._id, field: 'totalAmount' });
                          setEditedValue(Number(purchase.totalAmount));
                        }}
                      >
                        {editingCell.purchaseId === purchase._id && editingCell.field === 'totalAmount' ? (
                          <input
                            type="number"
                            value={editedValue}
                            onChange={(e) => setEditedValue(Number(e.target.value))}
                            onBlur={() => handleUpdate(purchase._id, 'totalAmount', Number(editedValue))}
                            onKeyDown={(e) => e.key === "Enter" && handleUpdate(purchase._id, 'totalAmount', Number(editedValue))}
                            onKeyUp={(e) => e.key === "Escape" && handleUpdate()}
                            autoFocus
                          />
                        ) : (
                          purchase.totalAmount
                        )}
                      </td>


                      {/* Payment Method */}
                      <td headers="paymentMethod" title="Double-Click to Edit"
                        onDoubleClick={() => {
                          setEditingCell({ purchaseId: purchase._id, field: 'paymentMethod' });
                          setEditedValue(purchase.paymentMethod);
                        }}
                      >
                        {editingCell.purchaseId === purchase._id && editingCell.field === 'paymentMethod' ? (
                          <select
                            value={editedValue}
                            onChange={e => handleUpdate(purchase._id, 'paymentMethod', e.target.value)}
                            onBlur={() => handleUpdate(purchase._id, 'paymentMethod', editedValue)}
                            onKeyDown={e => e.key === "Escape" && handleUpdate()}
                            autoFocus
                          >
                            <option selected={editedValue === "cash"}>Cash</option>
                            <option selected={editedValue === "online"}>Online</option>
                          </select>
                        ) : (
                          purchase.paymentMethod
                        )}
                      </td>


                      {/* Purchase Date */}
                      <td headers="purchaseDate" title="Double-Click to Edit"
                        onDoubleClick={() => {
                          setEditingCell({ purchaseId: purchase._id, field: 'purchaseDate' });
                          setEditedValue(purchase.purchaseDate ? new Date(purchase.purchaseDate).toISOString().split('T')[0] : '');
                        }}
                      >
                        {editingCell.purchaseId === purchase._id && editingCell.field === 'purchaseDate' ? (
                          <input
                            type="date"
                            value={editedValue}
                            onChange={(e) => setEditedValue(e.target.value)}
                            onBlur={() => handleUpdate(purchase._id, 'purchaseDate', editedValue)}
                            onKeyDown={(e) => e.key === "Enter" && handleUpdate(purchase._id, 'purchaseDate', editedValue)}
                            onKeyUp={(e) => e.key === "Escape" && handleUpdate()}
                            autoFocus
                          />
                        ) : (
                          formatDate(purchase.purchaseDate)
                        )}
                      </td>

                      <td headers="actions" className="flex gap-x-2">
                        <img
                          src={deleteIcon}
                          alt="Delete button"
                          className="inline-block cursor-pointer"
                          onClick={() => deletePurchase(purchase?._id)}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-[#E83535] font-bold p-4">
                      No Purchases Found.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </section>
      </main>

      <Footer />

      {/* Popup */}
      {showPopup && <Popup isSuccess={popupData.isSuccess} message={popupData.message} onClose={() => setShowPopup(false)} />}
      {loader && <Loader />}
    </>
  );
};
